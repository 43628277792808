/* ========================================================================
 * template
 * ======================================================================== */
body.app {
    background: #000 url('/images/hotstripecasino.com/bg.jpg') center top no-repeat; 
    font-family: Lato; font-size: 16px; font-style: normal;
}

.content-block{
    display: flex;
    justify-content: center;
    align-items: center;
}
.panel-login {
    margin-top: 10%;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    background: #350000; /* Old browsers */
    background: -moz-linear-gradient(top, #350000 0%, #000000 52%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #350000 0%,#000000 52%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #350000 0%,#000000 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#350000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
    padding: 30px 50px;
    color: #000033;
    border-width: 0;
    font-size: 1em;
}

.panel-login .panel-body {
    padding: 0;
    max-width: 300px;
}

.panel-login .form-group {
    
    margin-right: 0;
    margin-left: 0;
}

.panel-login .btn-primary {
    cursor:pointer;
    color:#000;
    font-size: 1.375em;
    background: #e89924;  
    padding: 6px 25px;
    border-width: 0;
    border-radius: 5px;
    width: 100%;
    font-size: 1.375em;
    text-transform: uppercase;
    height: 50px;
}

.panel-login .btn-primary:hover{
    background:#ec8313;
    transition: all 500ms ease;
}

.panel-login .btn-primary:active{
    background: #e2401a;
    border-width: 0;
}

.panel-login .copyright {
    text-transform: uppercase;
    color: #6a3738;
    text-align: center;
    font-size: 0.875em;
}

.panel-login .form-control {
    
    font-size: 1em;
    color: #666;
    border-radius: 5px;
    height: 50px;
    padding: 10px 20px; 
    margin-right: 0;
    margin-left: 0;
    
    background: #fff  ;
     border: 2px solid #ddd;
     -webkit-transition: all .3s;
     transition: all .3s;
}

.panel-login .form-control:focus {
    border: 2px solid #bbb;
}

.panel-login .logo {
    margin-bottom: 28px;
}

.panel-login #account{
    text-transform: uppercase;
}

.panel-login .help-block{
    color: red;
}

.panel-login #account::-webkit-input-placeholder {  //Chrome/Opera/Safari 
  text-transform: none;
}
.panel-login #account::-moz-placeholder {  //Firefox 19+ 
  text-transform: none;
}
.panel-login #account:-ms-input-placeholder { // IE 10+ 
  text-transform: none;
}
.panel-login #account:-moz-placeholder {  //Firefox 18- 
  text-transform: none;
}

@media only screen and (max-width: 325px)  {
    .panel-login .copyright {
       font-size: 0.7em;
    }   
   
    .panel-login {
        padding: 30px 30px;
    }
}

